import React, { useRef, useEffect, useState } from 'react';
import Link from 'next/link'
import Image from 'next/image'
import { ArrowRightIcon, GlobeAltIcon, ScaleIcon, PlusIcon, InformationCircleIcon, SparklesIcon } from '@heroicons/react/outline';
import StepForm from './form/HomepageStepForm/StepForm';
import ContactForm from './form/HomepageStepForm/ContactForm';
import Success from './form/HomepageStepForm/Success';

export default function LandingPageHome(props) {

    const [success, setSuccess] = useState(false)

    const topRef = useRef(null);

    useEffect(() => {
        // 👇️ scroll to top for this step
        topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });

    const slicedArray = props.lawsuits.slice(0, 6);

    return (
        <div>
            <div>
                <div className="relative banner">
                    <div className='z-0 relative'>
                        <div className='max-w-5xl px-2 md:px-4 py-5 md:py-5 mx-auto'>
                            <div className="my-auto text-center">
                                <h1 className="mt-5 text-3xl md:text-7xl tracking-normal font-extrabold text-white font-serif">
                                    Looking for legal help?
                                </h1>
                                <h2 className='text-xl md:text-2xl -mt-2 md:-mt-8 mb-5 text-white'>Get started by selecting a case</h2>

                                <p className="text-base md:text-lg font-semibold text-white sm:mt-5 max-w-2xl mx-auto">
                                    Year after year thousands of consumers are injured by faulty products or fall victim to false advertisement.
                                    Many are entitled to fiscal compensation but they don&apos;t know where to start.
                                </p>
                                {/* <div className='my-8 flex justify-center'>
                                    <a href="#form" id="CTAButton" className='group my-5 md:my-0 w-full md:w-fit text-white bg-red-600 hover:bg-red-800 text-base cursor-pointer font-bold rounded-sm uppercase flex items-center justify-center px-6 py-4'>
                                        Step 1: Secure your spot <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                                    </a>
                                </div> */}
                            </div>
                            {props.lawsuits?.length &&
                                <div className='grid lg:grid-cols-6 md:grid-cols-6 sm:grid-cols-2 grid-cols-1 gap-3 md:gap-5'>
                                    {slicedArray.map((lawsuit) => (
                                        <div className="col-span-1 z-0" key={lawsuit.id}>
                                            <div className="md:hover:translate-y-2 h-full group relative hover:opacity-75">
                                                <div className='relative flex justify-center rounded-lg items-center h-40 min-h-40 w-full bg-gray-200'>
                                                    {lawsuit.product_image && <Image
                                                        src={lawsuit.product_image}
                                                        alt={lawsuit.title}
                                                        layout="fill"
                                                        objectFit="cover"
                                                        className="drop-shadow w-full rounded-lg h-full object-center object-cover"
                                                    />}

                                                </div>

                                                <div className="flex flex-col mt-2">
                                                    <div>
                                                        <h3 className="mt-0 text-xl text-center">
                                                            <Link href={`/lawsuit/${lawsuit.slug}`} passHref>
                                                                <a id={`${lawsuit.title.replace(/\s/g, '')}FeaturedHomepageButton`} className='text-xl font-semibold text-white hover:text-white capitalize'>
                                                                    <span aria-hidden="true" className="absolute inset-0" />
                                                                    {lawsuit.title}
                                                                </a>
                                                            </Link>
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='bg-yellow-100 p-3 text-center'>
                <p className='font-bold text-yellow-900 text-base tracking-wide mb-0'>There are no lawyer fees unless you are awarded compensation.</p>
            </div> */}

            <div className='max-w-5xl px-2 md:px-4 py-20 mx-auto bg-white'>
                <div className='grid grid-cols-12 gap-6'>
                    <div className='col-span-12 md:col-span-6'>
                        <h2 className="mt-0 text-lg font-semibold text-gray-600 uppercase tracking-wide">Lawsuits</h2>
                        <h1 className="mt-2 mb-10 text-xl font-bold leading-8 tracking-tight text-gray-900 sm:text-2xl">
                            Current looking for those impacted by:
                        </h1>
                        <ul className='list-disc ml-10 text-lg text-gray-700'>
                            {slicedArray.map((lawsuit) => (
                                <li key={lawsuit.id}>{lawsuit.title}</li>
                            ))}
                        </ul>
                        <h3 className="mt-2 text-lg font-bold leading-8 tracking-tight text-gray-900">
                            You or a Loved One Injured by One of These?
                        </h3>
                        <p className="mt-4 max-w-2xl text-lg text-gray-600 mx-auto">
                            Secure your spot with a <Link href="#form"><a className='font-semibold underline'>free consultation</a></Link> from a law firm.
                        </p>
                        <p className="mt-4 max-w-2xl text-lg text-gray-600 mx-auto">
                            We do not take this process lightly. Only qualified people may become plaintiffs against the product manufacturers in state or federal court.
                        </p>
                        <p className="mt-4 max-w-2xl text-lg text-gray-600 mx-auto">The injured party (the plaintiff) sues the company (the defendant) for personal injuries sustained as a direct result of the product use.
                        </p>
                        <p className="mt-4 max-w-2xl text-lg text-gray-600 mx-auto">
                            Each side has to prove evidence in their own favor.
                        </p>
                        <p className="mt-4 max-w-2xl text-lg text-gray-600 mx-auto">
                            However cases are often settled outside of court.
                        </p>
                        <h2 className="mt-20 text-2xl font-bold leading-8 tracking-tight text-gray-900">
                            Not Sure if You Qualify?
                        </h2>
                        <p className="mt-4 max-w-2xl text-lg text-gray-600 mx-auto">
                            Start the <Link href="/lawsuit/eligibility-quiz"><a className='font-semibold underline'>1 min. Eligibility Check</a></Link> to see if you qualify for any of the above cases.
                        </p>
                        <p className="mt-4 text-lg text-gray-900">
                            <span className='font-bold'>Think you have a case?</span> Contact us <Link href="#form"><a className='font-semibold underline'>online</a></Link> for answers to your questions or schedule your complimentary consultation with a mass tort lawyer.
                        </p>
                    </div>
                    <div className='col-span-12 md:col-span-6'>
                        <div className='bg-zinc-100 md:p-10 p-4 rounded-lg' id="form">
                            <div>
                                {
                                    success ?
                                        <div refs={topRef}>
                                            <Success form="Contact" />
                                        </div>
                                        :
                                        <div>
                                            <h2 className="mt-0 text-lg font-semibold text-gray-800 uppercase tracking-wide">Step 1: Secure Your Spot</h2>
                                            <p className="mt-4 max-w-2xl text-base text-gray-500 mx-auto">
                                                Please fill out the form below for your free case review. We will never spam you, or sell your data to third parties.
                                            </p>
                                            <ContactForm source="Craftwise LLC" lawsuits={props.lawsuits} success={success} setSuccess={setSuccess} />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='text-center bg-zinc-100'>
                <div className='max-w-2xl px-2 md:px-4 py-20 mx-auto'>
                    <h2 className="mt-0 text-lg font-semibold text-sky-600 uppercase tracking-wide">Personal Injury lawsuits</h2>
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        Current Lawsuits
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto px-2">
                        Our team partners with law firms across the country to connect you with the resources you need to move forward with a case.
                    </p>
                    {props.lawsuits?.length &&
                        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5'>
                            {slicedArray.map((lawsuit) => (
                                <div className="col-span-1" key={lawsuit.id}>
                                    <div className="md:hover:translate-y-2 h-full group relative hover:opacity-75">
                                        <div className='relative flex justify-center rounded-lg items-center h-40 min-h-40 w-full bg-gray-200'>
                                            {lawsuit.product_image && <Image
                                                src={lawsuit.product_image}
                                                alt={lawsuit.title}
                                                layout="fill"
                                                objectFit="cover"
                                                className="z-0 drop-shadow w-full rounded-lg h-full object-center object-cover"
                                            />}

                                        </div>

                                        <div className="flex flex-col p-2 md:p-4">
                                            <div>
                                                <h3 className="mt-0 text-base text-center h-12">
                                                    <Link href={`/lawsuit/${lawsuit.slug}`} passHref>
                                                        <a id={`${lawsuit.title.replace(/\s/g, '')}FeaturedHomepageButton`} className='text-lg font-semibold text-gray-700 hover:text-black capitalize'>
                                                            <span aria-hidden="true" className="absolute inset-0" />
                                                            {lawsuit.title} Lawsuit
                                                        </a>
                                                    </Link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    }
                </div>
            </div> */}




            {/* <div>
                <div className="text-left bg-white">
                    <div className="text-center banner2 shadow drop-shadow-2xl">
                        <div className='h-72'></div>
                    </div>
                </div>
            </div> */}

            {/*banner */}
            {/* <div className="bg-gradient-to-r from-gray-50 to-gray-200">
                <ScaleIcon className='absolute z-0 h-48 w-48 text-gray-100' />
                <div className="z-20 relative mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block">Not sure where to start?</span>
                        <span className="block text-green-600">Take the lawsuit eligibility quiz</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="ml-3 inline-flex rounded-md shadow">
                            <Link href="/lawsuit/eligibility-quiz">
                                <a id="topCtaButton" className='md:h-auto w-full md:w-fit bg-green-600 text-lg md:text-xl  hover:bg-gradient-to-r hover:from-green-500 hover:to-green-700  cursor-pointer text-white font-bold rounded-sm uppercase flex items-center justify-center p-6'>
                                    Start Quiz <ArrowRightIcon className='ml-2 h-5 w-5' />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}



            {/* features */}
            {/* <div className='max-w-4xl  p-2 md:p-4 py-20 mx-auto'>
                <div className="bg-white py-12">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="lg:text-center">
                            <h2 className="text-lg font-semibold text-sky-600">Transactions</h2>
                            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                                A better way to send money
                            </p>
                            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                accusamus quisquam.
                            </p>
                        </div>

                        <div className="mt-10">
                            <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative">
                                        <dt>
                                            <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-sky-500 text-white">
                                                <feature.icon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                                        </dt>
                                        <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* End cta */}
            {/* <div className="bg-gradient-to-r from-sky-50 to-sky-200">
                <ScaleIcon className='absolute right-0 z-0 h-48 w-48 text-sky-100' />
                <div className="z-20 relative mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
                    <h2 className="mt-0 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                        <span className="block">Still not sure if you qualify?</span>
                        <span className="block text-sky-800">1 min. Eligibility Check</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="ml-3 inline-flex">
                            <Link href="/lawsuit/eligibility-quiz">
                                <a id="BottomCtaHomepageButton" className='group transition 
                        ease-in-out 
                        delay-150 
                        text-white
                        hover:-translate-y-1 
                        hover:drop-shadow-xl
                        bg-green-600
                        hover:bg-green-600
                        duration-300 
                        px-6 py-4
                        drop-shadow-lg 
                        tracking-widest
                        rounded-sm 
                        flex 
                        font-extrabold 
                        uppercase 
                        items-center 
                        justify-center'>
                                    Get Started <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
            <style>
                {
                    `.banner {
                background-image:  linear-gradient(to top right, rgba(0,0,0, 0.6), rgba(12,74,110, 0.8), rgba(2,132,199, 0.9)),
                 url("/equaljusticeunderlaw.jpg"); /* The image used */
                background-color: #000000; /* Used if the image is unavailable */
                height: 100%;
                /* Create the parallax scrolling effect */
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                z-index: auto;
            }

            .banner2 {
                background-image:  linear-gradient(to top right, rgba(0,0,0, 0.1) 40%, rgba(12,74,110, 0.1) 80%),
                 url("/equaljusticeunderlaw.jpg"); /* The image used */
                background-color: #000000; /* Used if the image is unavailable */
                height: 100%;
                /* Create the parallax scrolling effect */
                background-position: top;
                background-repeat: no-repeat;
                background-size: cover;
                z-index: auto;
            }`
                }
            </style>
        </div>

    );
}