import React, { useState, useCallback, useEffect } from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import Input from 'react-phone-number-input/input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import Success from './Success';
import { useRouter } from 'next/router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Link from 'next/link'
// import JSConfetti from 'js-confetti';

export default function ContactForm(props) {
    const initialState = {
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        agree: false,
        agree_provide_records: false,
        not_coached_compensated: false,
        message: "",
        subject: "Camp Lejeune Lawsuit",
    }

    const [dataArray, setDataArray] = useState(initialState)
    const router = useRouter()
    const { executeRecaptcha } = useGoogleReCaptcha();
    const errorObj = Object.keys(dataArray).reduce((prev, curr) => (prev[curr] = false, prev), {})
    //create an object of key value pair set to false each with a key === names of all basic form field keys
    const [errors, setErrors] = useState({ ...errorObj })
    const [phone, setPhone] = useState(dataArray.phone_number);
    const [haveEmail, setHaveEmail] = useState(true)
    const [loading, setLoading] = useState(false)

    const [ip, setIp] = useState()

    const getIp = async () => {
      // Connect ipapi.co with fetch()
      const response = await fetch('https://ipapi.co/json/')
      const data = await response.json()
      // Set the IP address to the constant `ip`
      setIp(data.ip)
    }
  
    // Run `getIP` function above just once when the page is rendered
    useEffect(() => {
      getIp()
    }, [])

    const updateFieldValue = (name, value) => {
        let newDataArray = { ...dataArray };
        newDataArray[name] = value
        setDataArray(newDataArray)
        // console.log("updated", value)
    }

    const handleSetPhone = (value) => {
        setPhone(value)
    }

    const handleEmailToggle = (value) => {
        setHaveEmail(value)
        if (value === false) {
            updateFieldValue("email_address", "")
        }
    }

    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z ]$'
    );

    const validDataArray = () => {
        let updateErrorObj = { ...errors };
        Object.entries(dataArray).some(([key, value]) => {
            if (key === "email_address") {
                updateErrorObj[key] = false
            } else if (key === 'phone_number') {
                if (!phone || phone.replace("+", '').length !== 11) {
                    updateErrorObj[key] = true
                    console.log("error", key, phone)
                } else {
                    updateErrorObj[key] = false

                }

            } else if (!value) {
                updateErrorObj[key] = true;
                // console.log("error", key, value)
            } else {
                updateErrorObj[key] = false
            }
        })
        setErrors(updateErrorObj)
        return (Object.values(updateErrorObj).includes(true)) ? false : true
    }


    const notifyWebhook = async (url, body) => {
        // console.log("POST", url, body)
        return fetch(url, {
            mode: 'no-cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    }

    const date = new Date();

    const pst = date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    const handleSubmitCallback = useCallback(() => {
        setLoading(true)
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            setLoading(false)
            return;
        }
        executeRecaptcha("contactFormSubmit").then((gReCaptchaToken) => {
            console.log(gReCaptchaToken, "response Google reCaptcha server");
            if (!document.querySelector("#leadid_token")?.getAttribute('value')) {
                console.log("no leadid token")
                setTimeout(handleSubmitCallback, 4000) //four seconds
            } else {
                try {
                    dataArray["phone_number"] = phone.replace("+", '')
                    //add timestamp
                    dataArray['time_stamp'] = pst + " PST"
                    dataArray['url'] = `https://thelegalcanary.com${router.asPath}`
                    dataArray['additional'] = `Subject:${dataArray.subject} | Message:${dataArray.message}`
                    dataArray["leadid_token"] = document.querySelector("#leadid_token")?.getAttribute('value')
                    dataArray['ip'] = ip
                    // dataArray['did'] = did

                    //use zapier
                    notifyWebhook(props.source.includes("Craftwise") ? "https://hooks.zapier.com/hooks/catch/13095819/bcnudmq/" : "", dataArray)
                    // console.log("data", dataArray)
                    // jsConfetti.addConfetti()
                    // props.setSuccess(true)
                    router.push("/confirmation/success")

                } catch (error) {
                    console.error('error', error);
                } finally {

                }
            }
        });
    },
        [dataArray]
    );

    // useEffect(() => {
    //     return () => {
    //         setDataArray(initialState); // stop memory leak
    //     };
    // }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (validDataArray()) {
            // if no errors...
            handleSubmitCallback()
        } else {
            setLoading(false)
        }
    }


    return (

        <form>
            <p className='text-gray-900 text-base mb-10 uppercase tracking-widest'></p>
            <div className="grid grid-cols-12 gap-4">

                <div className="col-span-12 md:col-span-6">
                    <label htmlFor="first-name" className="hidden text-sm font-medium text-gray-700">
                        First Name
                    </label>
                    <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder='First Name'
                        value={dataArray.first_name}
                        autoComplete="given-name"
                        onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                        className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.first_name ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                    />
                    {errors.first_name &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                            <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid name.
                        </p>
                    }
                </div>

                <div className="col-span-12 md:col-span-6">
                    <label htmlFor="last-name" className="hidden text-sm font-medium text-gray-700">
                        Last Name
                    </label>
                    <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder='Last Name'
                        value={dataArray.last_name}
                        autoComplete="family-name"
                        onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                        className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.first_name ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                    />
                    {errors.last_name &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                            <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid name.
                        </p>
                    }
                </div>


                <div className="col-span-12 ">
                    <label htmlFor="phone-number" className="hidden text-sm font-medium text-gray-700">
                        Phone Number
                    </label>
                    <Input
                        defaultCountry="US"
                        country="US"
                        value={phone}
                        onChange={handleSetPhone}
                        placeholder="Phone Number"
                        className={`mt-1 text-gray-700 bg-white h-10 px-2 border block w-full rounded-md ${errors.phone_number ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                    />
                    {errors.phone_number &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                            <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid U.S. phone number.
                        </p>
                    }
                </div>
                <div className="col-span-12">


                    {haveEmail &&
                        <div className="col-span-12 ">
                            <label htmlFor="email-address" className="hidden text-sm font-medium text-gray-700">
                                Email Address
                            </label>
                            <input
                                type="text"
                                name="email_address"
                                id="email_address"
                                placeholder='Email Address'
                                value={dataArray.email_address}
                                autoComplete="email"
                                onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                                className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.email_address ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                            />
                            {errors.email_address &&
                                <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                                    <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid email address.
                                </p>
                            }
                        </div>
                    }

                    {/* <div className="flex items-start mt-5">
                        <div className="flex h-5 items-center">
                            <input
                                id="have_email"
                                name="have_email"
                                type="checkbox"
                                className="h-5 w-5 rounded border-gray-300 text-teal-600 focus:ring-teal-500"
                                checked={!haveEmail}
                                // onClick={(event) => handleEmailToggle(!event.target.checked)}
                                onChange={(event) => handleEmailToggle(!event.target.checked)}
                                value={!haveEmail}
                            />
                        </div>
                        <div className="ml-3 text-base">
                            <label htmlFor="have_email" className="block text-sm font-medium text-gray-700">
                                I don&apos;t have an email address
                            </label>
                        </div>
                    </div> */}
                </div>
                <div className='col-span-12  border-t-2 w-full border-gray-300 py-1'></div>

                <div className="col-span-12">
                    <label htmlFor="subject" className="text-sm font-medium text-gray-700">
                        Select a case:
                    </label>
                    <div className='col-span-3'>
                        <select
                            id="subject"
                            name="subject"
                            autoComplete="case"
                            onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                            className="text-gray-700 mt-1 h-10 block w-full rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        >
                            {props.lawsuits.map((lawsuit) => {
                                return <option className="text-gray-700" key={lawsuit.id} value={`${lawsuit.title} Lawsuit`}>{lawsuit.title} Lawsuit</option>
                            })
                            }
                        </select>
                    </div>
                    {/* <input
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder='Subject'
                        maxLength="50"
                        value={dataArray.subject}
                        onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                        className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.subject ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                    />
                    <p className='mb-0 text-xs text-gray-500 italic'>(50 character max)</p> */}
                    {/* <input
                        type="text"
                        name="additional"
                        id="additional"
                        value={dataArray.additional}
                        autoComplete="given-name"
                        onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                        className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.additional ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                    /> */}
                    {errors.subject &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                            <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a subject.
                        </p>
                    }
                </div>
                <div className="col-span-12">
                    <label htmlFor="message" className="hidden text-sm font-medium text-gray-700">
                        Message (300 character max)
                    </label>
                    <textarea
                        rows={3}
                        name="message"
                        maxLength="250"
                        placeholder='Message'
                        id="message"
                        value={dataArray.additional}
                        onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                        className={`text-gray-700 mt-1 block w-full rounded-md ${errors.message ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                    />
                    <p className='mb-0 text-xs text-gray-500 italic'>(300 character max)</p>
                    {/* <input
                        type="text"
                        name="additional"
                        id="additional"
                        value={dataArray.additional}
                        autoComplete="given-name"
                        onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                        className={`text-gray-700 mt-1 h-10 block w-full rounded-md ${errors.additional ? "border-red-500" : "border-gray-300"}  focus:border-green-500 focus:ring-green-500 sm:text-sm`}
                    /> */}
                    {errors.message &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                            <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a message.
                        </p>
                    }
                </div>


                <div className="col-span-12 flex items-start">
                    <input
                        id="not_coached_compensated"
                        name="not_coached_compensated"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                        checked={dataArray.not_coached_compensated}
                        onChange={(event) => updateFieldValue("not_coached_compensated", event.target.checked)}
                    />
                    <label htmlFor="not_coached_compensated" className="ml-2 block text-sm text-gray-600">
                        I confirm that I was not coached and I was not offered any compensation for this claim.
                    </label>
                </div>
                {errors.not_coached_compensated &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                        <XCircleIcon className="h-4 w-4 mr-1" /> Please agree to the terms and conditions above to proceed.
                    </p>
                }

                <div className="col-span-12 flex items-start">
                    <input
                        id="agree_provide_records"
                        name="agree_provide_records"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                        checked={dataArray.agree_provide_records}
                        onChange={(event) => updateFieldValue("agree_provide_records", event.target.checked)}
                    />
                    <label htmlFor="agree_provide_records" className="ml-2 block text-sm text-gray-600">
                        I understand I will be asked for medical records, and if falsified, I will be held liable
                        for knowingly providing false information and the Law Firm reserves the right to seek all available remedies under the law.
                    </label>
                </div>
                {errors.agree_provide_record &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                        <XCircleIcon className="h-4 w-4 mr-1" /> Please agree to the terms and conditions above to proceed.
                    </p>
                }

                <input id="leadid_token" name="universal_leadid" className="col-span-12" value="" type="hidden" />

                <div className="col-span-12 flex items-start">
                    <input
                        id="leadid_tcpa_disclosure"
                        name="leadid_tcpa_disclosure"
                        type="checkbox"
                        checked={dataArray.agree}
                        className="h-4 w-4 rounded border-gray-300 text-teal-600 focus:ring-teal-600"
                        onChange={(event) => updateFieldValue("agree", event.target.checked)}
                    />
                    <label htmlFor="leadid_tcpa_disclosure" className="ml-2 block text-sm text-gray-600">
                        I understand that by submitting my request, I confirm that I have provided my legal name and my own phone number
                        and I&apos;ve read & agreed to the <Link href="/privacy-policy"><a className='mx-1 underline'>Privacy Policy</a></Link> and
                        <Link href="/terms-conditions"><a className='mx-1 underline'>Terms &amp; Conditions</a></Link> of this site and that I gave my consent to
                        be contacted from thelegalcanary.com, and its  <Link href="/partners"><a className='mx-1 underline'>Partners</a></Link> by email or at the phone number I entered using automated
                        technology including recurring auto-dialers, pre-recorded messages, and text messages, even if my phone
                        number is a mobile number or is currently listed on any state, federal, or corporate &quot;Do Not Call&quot; list.
                        I understand there may be a charge from my wireless carrier for such communications.
                        Your accurate information is required for a free evaluation. To unsubscribe at any time, reply &quot;STOP&quot;. Message &amp; Data rates may apply
                    </label>
                </div>
                {errors.agree &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                        <XCircleIcon className="h-4 w-4 mr-1" /> Please agree to the terms and conditions above to proceed.
                    </p>
                }



            </div>

            <div className="mt-5 text-right">
                <button
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                    className={`
                            inline-flex 
                            items-center 
                            justify-center
                            py-4
                            px-2 
                            w-full
                            rounded-sm
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            ${loading ? "bg-gray-200 text-gray-500" : "bg-teal-500 hover:bg-teal-800 text-white"}
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500`}
                >
                    {loading ? "Loading..." : "Talk to a Lawyer Now"}
                </button>
            </div>
        </form>


    );
}
