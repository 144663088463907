import React, { useState } from 'react';
import Link from 'next/link'
import Image from 'next/image'
import { ArrowRightIcon, GlobeAltIcon, ScaleIcon, PlusIcon, InformationCircleIcon, SparklesIcon } from '@heroicons/react/outline';

export default function LandingPageHome(props) {

    const [newHref, setNewHref] = useState(props.lawsuits[0].slug)

    const setHref = (value) => {
        setNewHref(value)
    }

    const slicedArray = props.lawsuits.slice(0, 3);

    return (
        <div>
            <div>
                <div className="relative bg-gradient-to-t from-sky-700  to-sky-800">
                    <ScaleIcon className='hidden md:block absolute bottom-0 right-0 z-10 h-96 w-96 text-sky-800' />
                    <div className='absolute bottom-1 right-0 z-0 h-full w-full rounded-l-full bg-sky-700' />
                    <div className='z-20 relative'>
                        <div className='max-w-4xl px-2 md:px-4 py-10 md:py-20 mx-auto grid gap-10 grid-cols-1 md:grid-cols-2'>
                            <div className="my-auto text-center md:text-left">
                                <h1 className="mt-0 text-3xl md:text-5xl tracking-tight font-extrabold text-white font-serif">
                                    Up-to-date on recent product and personal injury lawsuits?
                                </h1>
                                <p className="mt-3 text-base text-white sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0">
                                    We&apos;re here to help those who have experienced severe loss and pain find the proper legal resources
                                </p>

                                <p className="mt-10 italic text-base text-white sm:mt-5 sm:max-w-xl sm:mx-auto lg:mx-0">
                                    What lawsuit are your looking to know more about?
                                </p>
                                <div className='my-2 md:grid grid-cols-6 gap-1'>
                                    <div className='col-span-4'>
                                        <select
                                            id="lawsuits"
                                            name="lawsuits"
                                            autoComplete="year"
                                            onChange={(event) => setHref(event.target.value)}
                                            className="block mb-2 h-14 text-base text-gray-700 rounded-sm w-full border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500"
                                        >
                                            {props.lawsuits.map((lawsuit) => {
                                                return <option className="text-gray-700" key={lawsuit.id} value={lawsuit.slug}>{lawsuit.title}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                    <Link href={`/lawsuit/${newHref}`}>
                                        <a id="SearchHomepageButton" className='col-span-2 group mb-2 transition 
                        ease-in-out 
                        delay-150 
                        text-white
                        hover:-translate-y-1 
                        hover:drop-shadow-xl
                        bg-green-600
                        hover:bg-green-600
                        duration-300 
                        px-6 py-4
                        drop-shadow-lg 
                        tracking-widest
                        rounded-sm 
                        flex 
                        font-extrabold 
                        uppercase 
                        items-center 
                        justify-center'>
                                            Search
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                {slicedArray?.length &&
                                    <div className='grid grid-cols-1 gap-5 lg:grid-cols-1'>
                                        {slicedArray.map((lawsuit) => (
                                            <div key={lawsuit.id}>
                                                <div className="bg-white grid grid-cols-1 md:grid-cols-4 hover:bg-zinc-100 md:hover:translate-x-2 h-full rounded-lg shadow group relative hover:opacity-75">
                                                    <div className='col-span-2 rounded-t md:rounded-l relative flex justify-center items-center h-24 md:h-auto w-full bg-gray-200'>
                                                        {lawsuit.product_image && <Image
                                                            src={lawsuit.product_image}
                                                            alt={lawsuit.title}
                                                            layout="fill"
                                                            objectFit="cover"
                                                            className="rounded-t md:rounded-l z-0 drop-shadow w-full h-full object-center object-cover"
                                                        />}

                                                    </div>

                                                    <div className="col-span-2 flex flex-col p-2 md:p-4">
                                                        <div className='my-auto'>
                                                            <h3 className="mb-0 text-base">
                                                                <Link href={`/lawsuit/${lawsuit.slug}`} passHref>
                                                                    <a id={`${lawsuit.title.replace(/\s/g, '')}CtaHomepageButton`} className='text-base font-semibold text-gray-700 hover:text-black capitalize'>
                                                                        <span aria-hidden="true" className="absolute inset-0" />
                                                                        {lawsuit.title} Lawsuit
                                                                    </a>
                                                                </Link>
                                                            </h3>
                                                            <p className="text-xs mt-2 mb-3 text-gray-500">
                                                                {lawsuit.subtitle}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='max-w-4xl px-2 md:px-4 py-20 mx-auto bg-white'>
                <div className="text-center">
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        TheLegalCanary.com Team
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto px-2">
                        Our team works tirelessly to connect you to the proper resources. Get the compensation you deserve, free of charge.
                    </p>
                </div>
                <div className='grid grid-col-1 md:grid-cols-2 gap-10'>
                    <div className='flex flex-col items-center justify-center lg:flex-row mb-5'>
                        <div className='max-w-xs mx-auto lg:mx-0 text-base col-span-3 text-gray-700 text-left'>
                            <h4 className='mt-0 mb-2 font-semibold text-gray-800 text-xl'>How are legal resources free of charge?</h4>
                            <p className='mb-2'>We are in the business of helping people, so we refuse to partner with scam law firms and fake offices.</p>
                            Our network of <Link href="/partners" ><a className='underline'>national law firm partners</a></Link> offer a free consultation so see if you have a case.
                        </div>
                    </div>
                    <div className='my-10 relative flex justify-center items-center h-60 md:h-80 rounded-sm shadow-lg drop-shadow-lg w-full bg-gray-200'>
                        <Image
                            src={`/2.jpg`}
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                            className="z-0 drop-shadow rounded-sm w-full h-full object-center object-cover"
                        />
                    </div>
                    <div className='my-10 relative flex justify-center items-center h-60 md:h-80 rounded-sm shadow-lg drop-shadow-lg w-full bg-gray-200'>
                        <Image
                            src={`/handshake.jpg`}
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                            className="z-0 drop-shadow rounded-sm w-full h-full object-center object-cover"
                        />
                    </div>
                    <div className='flex flex-col items-center justify-center lg:flex-row mb-5'>
                        <div className='max-w-xs mx-auto lg:mx-0 text-base col-span-3 text-gray-700 text-left'>
                            <h4 className='mt-0 mb-2 font-semibold text-gray-800 text-xl'>No fees unless you win the case</h4>
                            <p className='mb-2'>
                                Attorneys will review your completed documents and ultimately determine if you have a case. And there are no lawyer fees unless you are awarded compensation.
                            </p>
                            We cannot guarantee you qualify for an ongoing lawsuit. Case eligibility is ultimately determined by each law firm.
                        </div>
                    </div>
                </div>
            </div>





            <div className="relative bg-gradient-to-r from-white to-green-100 text-center">
                <div className="z-20 relative mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                    <h2 className="mt-0 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block">Featured Lawsuits</span>
                        <span className="block text-emerald-800">See if you qualify for a complimentary case review.</span>
                    </h2>
                    <p className="mt-3 text-lg text-gray-800">
                        Have you or a loved one experienced personal injury and even cancer because of the following product use?
                    </p>
                    <div>
                        {props.lawsuits?.length &&
                            <div className='max-w-3xl mx-auto grid md:grid-cols-3 gap-5 grid-cols-1'>
                                {props.lawsuits.map((lawsuit) => (
                                    <div className="col-span-1" key={lawsuit.id}>
                                        <div className="bg-white  hover:bg-zinc-100 md:hover:translate-y-2 h-full rounded-lg shadow group relative hover:opacity-75">
                                            <div className='rounded-t md:rounded-l relative flex justify-center items-center h-40 min-h-40 w-full bg-gray-200'>
                                                {lawsuit.product_image && <Image
                                                    src={lawsuit.product_image}
                                                    alt={lawsuit.title}
                                                    layout="fill"
                                                    objectFit="cover"
                                                    className="rounded-t md:rounded-l z-0 drop-shadow w-full h-full object-center object-cover"
                                                />}

                                            </div>

                                            <div className="flex flex-col p-2 md:p-4">
                                                <div>
                                                    <h3 className="mt-0 text-base">
                                                        <Link href={`/lawsuit/${lawsuit.slug}`} passHref>
                                                            <a id={`${lawsuit.title.replace(/\s/g, '')}FeaturedHomepageButton`} className='text-lg font-semibold text-gray-700 hover:text-black capitalize'>
                                                                <span aria-hidden="true" className="absolute inset-0" />
                                                                {lawsuit.title} Lawsuit
                                                            </a>
                                                        </Link>
                                                    </h3>
                                                    {/* <p className="mb-0 text-base mt-5 h-15 text-gray-500">
                                                        {lawsuit.subtitle}
                                                    </p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        }
                    </div>
                </div>
            </div>

            <div>
                <div className="text-left bg-white">
                    <div className='grid grid-cols-1 md:grid-cols-2'>
                        <div className='relative bg-gradient-to-t from-sky-700  to-sky-800 flex items-center justify-center shadow drop-shadow-2xl'>
                            <div className='absolute bottom-0 right-0 z-0 h-full w-full rounded-r-full bg-sky-700' />
                            <div className='relative z-10 p-4 md:p-20'>
                                <p className="mt-10 text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl">
                                    Our mission is to provide legal resources for free.
                                </p>
                                <p className="mb-0 mt-4 text-base text-white lg:mx-auto">
                                    Year after year thousands of consumers are injured by faulty products or fall victim to false advertisement.
                                    Many are entitled to fiscal compensation but they don&apos;t know where to start. Luckily, there are still lawyers out there who are seeking to do good and help those impacted by difficult circumstances.
                                </p>
                                <p className="mb-10 mt-4 text-sm text-white lg:mx-auto">
                                    Our team at TheLegalCanary works tirelessly to connect you with qualified legal teams, free of charge.
                                </p>
                                <div className='my-10'>
                                    <Link href="/lawsuit/eligibility-quiz">
                                        <a id="missionHomepageButton" className='my-5 md:my-0 w-full md:w-fit border-2 border-white text-white bg-transparent hover:border-red-600  hover:bg-gradient-to-r hover:from-red-500 hover:to-red-700  text-base cursor-pointer font-bold rounded-sm uppercase flex items-center justify-center px-6 py-4'>
                                            Think you have a case?
                                        </a>
                                    </Link>
                                </div>


                            </div>
                            {/* <div className='grid md:grid-cols-2 gap-4'>

                                    <div className='border-t border-gray-200'>
                                        <p className='mb-0 font-semibold text-gray-500 mt-2'>Created</p>
                                        <p className='font-black text-4xl text-gray-800'>2022</p>
                                    </div>
                                    <div className='border-t border-gray-200'>
                                        <p className='mb-0 font-semibold text-gray-500 mt-2'>Beta Users</p>
                                        <p className='font-black text-4xl text-gray-800'>31</p>
                                    </div>
                                    <div className='border-t border-gray-200'>
                                        <p className='mb-0 font-semibold text-gray-500 mt-2'>Patterns Already Created</p>
                                        <p className='font-black text-4xl text-gray-800'>84</p>
                                    </div>
                                </div> */}

                        </div>
                        <div className="order-2 md:order-1 h-96 md:h-full text-center shadow drop-shadow-2xl">
                            <Image
                                src={`/3.jpg`}
                                layout="fill"
                                objectFit="cover"
                                objectPosition="center"
                                className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                                alt="Lawyers"
                            />
                            <div className="absolute w-full h-full object-center object-cover lg:w-full lg:h-full bg-sky-800 bg-opacity-75">

                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div className='max-w-4xl px-2 md:px-4 py-20 mx-auto bg-white'>
                <div className="text-center">
                    <h2 className="mt-0 text-lg font-semibold text-sky-600 uppercase tracking-wide">Finding a personal injury lawyer</h2>
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                        How do I get the proper legal help I need?
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto px-2">
                        Our team partners with law firms across the country to connect you with the resources you need to move forward with a case.
                    </p>
                </div>
                <div className='grid grid-col-1 md:grid-cols-2 gap-10'>
                    <div className='flex flex-col lg:flex-row mb-5'>
                        <div className='bg-sky-100 text-sky-800 text-2xl font-bold h-16 w-16 mx-auto flex items-center justify-center rounded-full'>1</div>
                        <div className='max-w-xs mx-auto lg:mx-0 text-base col-span-3 text-gray-700 text-center lg:text-left'>
                            <h4 className='mt-0 mb-2 font-bold text-gray-800 text-xl'>Verify your contact information</h4>
                            <p className='font-semibold mb-2'>We are in the business of helping people, so we need to make sure we can reach you.</p>
                            A member of our team will contact you to verify if the contact information you provided is correct.
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row mb-5'>
                        <div className='bg-sky-100 text-sky-800 text-2xl font-bold h-16 w-16 mx-auto flex items-center justify-center rounded-full'>2</div>
                        <div className='max-w-xs mx-auto lg:mx-0 text-base col-span-3 text-gray-700 text-center lg:text-left'>
                            <h4 className='mt-0 mb-2 font-bold text-gray-800 text-xl'>Get connected with a law firm</h4>
                            <p className='font-semibold mb-2'>Once we confirm your contact information, you&apos;re phone call will be directly transferred.</p>
                            You will be transferred to one of our law firm partners who will conduct your complimentary case review.
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row mb-5'>
                        <div className='bg-sky-100 text-sky-800 text-2xl font-bold h-16 w-16 mx-auto flex items-center justify-center rounded-full'>3</div>
                        <div className='max-w-xs mx-auto lg:mx-0 text-base col-span-3 text-gray-700 text-center lg:text-left'>
                            <h4 className='mt-0 mb-2 font-bold text-gray-800 text-xl'>Review the lawsuit agreement</h4>
                            <p className='font-semibold mb-2'>
                                If you meet match the lawsuit&apos;s requirements, the law firm will send a document package for you to
                                sign and return.</p>
                            This agreement is between you and the law firm and will cover retainer agreement, medical release forms, HIPPA disclosures and more.</div>
                    </div>
                    <div className='flex flex-col lg:flex-row mb-5'>
                        <div className='bg-sky-100 text-sky-800 text-2xl font-bold h-16 w-16 mx-auto flex items-center justify-center rounded-full'>4</div>

                        <div className='max-w-xs mx-auto lg:mx-0 text-base col-span-3 text-gray-700 text-center lg:text-left'>
                            <h4 className='mt-0 mb-2 font-bold text-gray-800 text-xl'>Finalize case eligibility</h4>
                            <p className='font-semibold mb-2'>
                                Attorneys will review your completed documents and ultimately determine if you have a case.
                            </p>
                            We cannot guarantee you qualify for an ongoing lawsuit. Case eligibility is ultimately determined by each law firm.
                        </div>
                    </div>
                </div>
            </div>



            {/*banner */}
            {/* <div className="bg-gradient-to-r from-gray-50 to-gray-200">
                <ScaleIcon className='absolute z-0 h-48 w-48 text-gray-100' />
                <div className="z-20 relative mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block">Not sure where to start?</span>
                        <span className="block text-green-600">Take the lawsuit eligibility quiz</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="ml-3 inline-flex rounded-md shadow">
                            <Link href="/lawsuit/eligibility-quiz">
                                <a id="topCtaButton" className='md:h-auto w-full md:w-fit bg-green-600 text-lg md:text-xl  hover:bg-gradient-to-r hover:from-green-500 hover:to-green-700  cursor-pointer text-white font-bold rounded-sm uppercase flex items-center justify-center p-6'>
                                    Start Quiz <ArrowRightIcon className='ml-2 h-5 w-5' />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}



            {/* features */}
            {/* <div className='max-w-4xl  p-2 md:p-4 py-20 mx-auto'>
                <div className="bg-white py-12">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="lg:text-center">
                            <h2 className="text-lg font-semibold text-sky-600">Transactions</h2>
                            <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                                A better way to send money
                            </p>
                            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
                                accusamus quisquam.
                            </p>
                        </div>

                        <div className="mt-10">
                            <dl className="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative">
                                        <dt>
                                            <div className="absolute flex h-12 w-12 items-center justify-center rounded-md bg-sky-500 text-white">
                                                <feature.icon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                                        </dt>
                                        <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </div> */}




            {/* End cta */}
            <div className="bg-gradient-to-r from-sky-50 to-sky-200">
                <ScaleIcon className='absolute right-0 z-0 h-48 w-48 text-sky-100' />
                <div className="z-20 relative mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
                    <h2 className="mt-0 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        <span className="block">Ready to Get Started?</span>
                        <span className="block text-sky-800">See if you qualify with the 1 Min Quiz.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="ml-3 inline-flex">
                            <Link href="/lawsuit/eligibility-quiz">
                                <a id="BottomCtaHomepageButton" className='group transition 
                        ease-in-out 
                        delay-150 
                        text-white
                        hover:-translate-y-1 
                        hover:drop-shadow-xl
                        bg-green-600
                        hover:bg-green-600
                        duration-300 
                        px-6 py-4
                        drop-shadow-lg 
                        tracking-widest
                        rounded-sm 
                        flex 
                        font-extrabold 
                        uppercase 
                        items-center 
                        justify-center'>
                                    Get Started <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}